@font-face {
    font-family: Sora;
    src: url(../assets/fonts/sora/Sora-Regular.ttf);
}
.proj-types-title {
    text-align: center;
    color: black;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 25px;
    padding: 25px 0px;
    border-bottom: 1px solid #000;
    margin-bottom: 25px;
    border-top: 1px solid #000;
    font-family:'Sora';
}
.company {
    margin-bottom: 10px;
    font-size: 18px;
    font-family:'Sora';
}
.comapny-label {
    font-weight: bold;
    font-family:'Sora';
}
.proj-statement {
    background-color: #ffdb00;
    padding: 9px 12px;
    border-radius: 3px;
    margin-bottom: 15px;
    font-family:'Sora';
}